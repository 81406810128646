<template>
    <div class="vue-template forbgl">
        <div class="container ">
            <div class="row mt-5 mb-5">
                <div class="col-md-4 offset-md-4 vertical-center mt-5 loginw">
                    <h3 class="text-center">Log in</h3>

                    <form class="ml-0" v-if="login_hide == false">
                        <div class="form-group">
                            <label>Email</label>
                            <input type="email" class="form-control" v-model="email" />
                        </div>

                        <div class="form-group">
                            <label>Password</label>
                            <input type="password" class="form-control" v-model="password" />
                        </div>
                        <div class="status" id="login-status"></div>
                        <div class="my-2">
                            <router-link :to="{ name: 'forgot_password' }"
                                >Forgot password?</router-link
                            >
                        </div>
                        <button type="submit" @click="submit" class="btn submit-btn login-btn">
                            {{ processingForm ? "Please Wait..." : "Login Now" }}
                        </button>
                    </form>

                    <form class="ml-0" v-if="otp_show == true">
                        <div class="form-group">
                            <label>Enter OTP</label>
                            <div class="mobile-text">
                                Enter the code we just send on your Email.
                            </div>
                            <input
                                type="text"
                                class="form-control"
                                name="otp"
                                v-model="otp"
                                maxlength="4"
                            />
                        </div>
                        <div v-if="ress && ress.otp" class="text-danger">{{ ress.otp[0] }}</div>

                        <div class="status" id="login-status"></div>
                        <input
                            type="submit"
                            @click="send_otp"
                            class="submit-btn login-btn registerbtn"
                            value="Send"
                        />
                    </form>
                </div>
            </div>
        </div>

        <h1 v-if="user">{{ user.firstName }}</h1>
        <p class="error" v-if="err">{{ err }}</p>
    </div>
</template>

<script>
// import { HTTP } from "../_helper/http-constants";
import axios from "axios"
import User from "../apis/User"
import Api from "../apis/Api"
import scrollTop from "./utils/scrollTop"
import { mapActions, mapState } from "vuex"

export default {
    name: "login",
    data() {
        return {
            err: "",
            email: "",
            password: "",
            otp: "",
            login_hide: false,
            otp_show: false,
            ress: {},
            processingForm: false,
            action: "/api/login",
        }
    },
    computed: {
        ...mapState("auth", {
            user: "user",
            isLogged: "authenticated",
        }),
    },
    methods: {
        ...mapActions("auth", {
            login: "login",
        }),
        async submit(e) {
            e.preventDefault()
            if (this.processingForm) return

            this.processingForm = true

            const form = new FormData()

            try {
                await this.login({
                    email: this.email,
                    password: this.password,
                })
                this.$toasted.success("Signed in successfully", {
                    keepOnHover: true,
                    iconPack: "fontawesome",
                    icon: "check",
                    theme: "toasted-primary",

                    duration: 3000,
                })
                location.href = "dashboard"
            } catch (err) {
                console.log("Error in logging in ", err)
            }

            this.processingForm = false
        },
        // login: function(e) {
        //     if (this.processingForm) return
        //     e.preventDefault()

        //     this.processingForm = true
        //     const form = new FormData()

        //     form.append("email", this.email)
        //     form.append("password", this.password)

        //     User.login(form)
        //         .then((res) => {
        //             console.log(res)

        //             // uncomment the next 2 lines below if the step for otp confirmation is required.
        //             // this.login_hide = true
        //             // this.otp_show = true
        //             console.log("login res", res)

        //             // remove next line to enable otp login 2 factor auth
        //             this.send_otp(e, res.data.otp)
        //             this.processingForm = false
        //         })
        //         .catch(() => {
        //             // if (error.response.status === 422) {
        //             document.getElementById("login-status").innerHTML =
        //                 "<span class='warning' style='color: red;'>Email or Password is invalid.</span>"
        //             // this.errors = error.response.data.errors;
        //             // }
        //             this.processingForm = false
        //         })
        // },

        send_otp: function(e, otp) {
            e.preventDefault()
            const credentials = {
                otp: otp || this.otp,
                email: this.email,
            }
            console.log("otp for verification: ", credentials)
            Api.post("/verify", credentials)
                .then((res) => {
                    // if required to sign in via 2factor auth - (additional otp after username/pw, then uncomment it to setup auth)
                    this.setupAuth(res)
                })
                .catch((res) => {
                    if (res.response.status === 401) {
                        this.ress = res.response.data.errors || {}
                    }
                    console.log(res, res.data)
                    return res
                })
        },

        setupAuth(res) {
            console.log("otp verify res", res.data)
            this.$root.$emit("login", true)
            localStorage.setItem("auth", "true")
            localStorage.setItem("user", JSON.stringify(res.data))

            this.$toasted.success("Signed in successfully", {
                keepOnHover: true,
                iconPack: "fontawesome",
                icon: "check",
                theme: "toasted-primary",

                duration: 3000,
            })
            location.href = "dashboard"
        },
    },
    mounted() {
        scrollTop()

        if (this.isLogged) {
            this.$router.push({ name: "dashboard" })
        } else {
            this.$router.push({ name: "login" })
        }
    },
}
</script>

<style>
.mobile-text {
    color: #989696b8;
    font-size: 15px;
}
</style>
